import React from "react"
import serviceone from "../../../static/images/serviceone.png"
import Technology from "../../../static/images/technolgy.jpg"
import { data } from "autoprefixer"

export default function sectionone() {
  return (
    <div className="" style={{fontFamily:'poppins'}}>
      {/* <section class=" px-12 lg:py-16 lg:px-20 bg-white overflow-hidden" style={{fontFamily:'poppins'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center -m-8">
      <div class="w-full md:w-1/2 p-8">
        <img class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000" src={DataMining} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <div class="md:max-w-xl">
          <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">&#x1F44B; Our Services</p>
          <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">Data Mining and Engineering</h2>
          <div class="flex flex-wrap mb-5 pb-10 -m-8">
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">Extraction</h3>
                    <p class="text-gray-600 font-medium leading-relaxed">Extract actionable & decision-fuelling insights from large datasets using AWS, Google Cloud and Azure</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">Ready-to-use Data</h3>
                    <p class="text-gray-600 font-medium leading-relaxed">Data processing, data cleaning, and preparing ready-to-use data for further analysis by data analysts and scientists.
</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">Transformation</h3>
                    <p class="text-gray-600 font-medium leading-relaxed">Transforming data ready for analysis that can deliver progress and transformation for your business.
</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">Human Intelligence</h3>
                    <p class="text-gray-600 font-medium leading-relaxed">Business decisions which are driven through a blend of data, algorithms, computing power, and human intelligence.
</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="md:inline-block">
            <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

      <section class=" py-12 lg:px-8">
        <div class="max-w-7xl mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {/* <!-- Text Section --> */}
            <div class="bg-white rounded-lg overflow-hidden shadow-lg p-6">
              <h2 class="text-base font-semibold leading-7 text-indigo-600">
                Our Services
              </h2>
              <h3 class="mt-2 text-3xl font-semibold tracking-tight text-blue-900 sm:text-4xl">
                Technology Solutions
              </h3>
              <p class="lg:text-lg leading-8 text-gray-600 mt-5">
                The company offers innovative technology solutions to support
                clients in streamlining their operations, leveraging data-driven
                insights, and embracing the power of machine learning and AI.
              </p>
              <p class="text-gray-600 mb-4">We provide:</p>
              <ul class="list-disc ml-6">
                <li>Insurtech Products and Services</li>
                <li>Decision making and Business Intelligence tool</li>
                <li>Software as a Service (SaaS) solutions</li>
              </ul>
            </div>

            {/* <!-- Placeholder for Image Section (if needed) --> */}
            <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                class="h-96 w-full transform hover:scale-105 transition ease-in-out duration-1000"
                src={Technology}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
